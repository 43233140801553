<template>
  <div class="container-fluid h-100 py-3 px-0">
    <div
      class="d-flex flex-column h-100 w-100 justify-content-start align-items-center"
    >
      <div id="imgModal" @click="closeImgModal" v-if="data.showImgModel">
        <span class="close-button" @click="closeImgModal">x</span>
        <img :src="data.currentHelpImage" alt="Help Image" class="img-full" />
      </div>
      <h5 class="text-center w-75 w-sm-100 align-self-center">
        Please fill in below:
      </h5>
      <div class="card form-card w-100" id="doorDetailForm">
        <div class="card-body form-card-body">
          <div class="form-group">
            <label for="doorQuantity" class="form-control-label"
              >Quantity</label
            >
            <input
              type="number"
              name="doorQuantity"
              id="doorQuantity"
              :class="{
                'is-invalid':
                  vv.doorQuantity.$dirty && vv.doorQuantity.$invalid,
              }"
              class="form-control form-control-lg"
              v-model="vv.doorQuantity.$model"
            />
            <div class="invalid-feedback">
              {{ vv?.doorQuantity?.$errors[0]?.$message }}
            </div>
          </div>

          <div class="divider"></div>

          <div class="form-group">
            <label for="doorMaterialId">Door Material</label>
            <select
              name="doorMaterialId"
              id="doorMaterialId"
              :class="{
                'is-invalid':
                  vv.doorMaterialId.$dirty && vv.doorMaterialId.$invalid,
              }"
              class="form-control form-control-lg"
              v-model="vv.doorMaterialId.$model"
            >
              <option value="" disabled>-----</option>
              <option
                v-for="option in data.materialOptions"
                :key="option.doorJambMaterialId"
                :value="option.doorJambMaterialId"
              >
                {{ option.doorJambMaterialDesc }}
              </option>
            </select>
            <div class="invalid-feedback">
              {{ vv?.doorMaterialId?.$errors[0]?.$message }}
            </div>
          </div>

          <div class="divider"></div>

          <div class="form-group">
            <label for="doorThicknessId">Door Thickness</label>
            <select
              name="doorThicknessId"
              :class="{
                'is-invalid':
                  vv.doorThicknessId.$dirty && vv.doorThicknessId.$invalid,
              }"
              id="doorThicknessId"
              class="form-control form-control-lg"
              v-model="vv.doorThicknessId.$model"
            >
              <option value="" disabled>-----</option>
              <option
                v-for="option in data.doorThicknessOptions"
                :key="option.doorThicknessId"
                :value="option.doorThicknessId"
              >
                {{ option.doorThicknessDesc }}
              </option>
            </select>
            <div class="invalid-feedback">
              {{ vv?.doorThicknessId?.$errors[0]?.$message }}
            </div>
          </div>

          <div class="divider"></div>

          <div class="form-group">
            <label for="doorJambMaterialId">Jamb Type</label>
            <select
              name="doorJambMaterialId"
              :class="{
                'is-invalid':
                  vv.doorJambMaterialId.$dirty &&
                  vv.doorJambMaterialId.$invalid,
              }"
              id="doorJambMaterialId"
              class="form-control form-control-lg"
              v-model="vv.doorJambMaterialId.$model"
            >
              <option value="" disabled>------</option>
              <option
                v-for="option in data.materialOptions"
                :key="option.doorJambMaterialId"
                :value="option.doorJambMaterialId"
              >
                {{ option.doorJambMaterialDesc }}
              </option>
            </select>
            <div class="invalid-feedback">
              {{ vv?.doorJambMaterialId?.$errors[0]?.$message }}
            </div>
          </div>

          <div class="divider"></div>

          <div class="form-group">
            <label for="boreCount">Number of Bores in Door</label>
            <input
              type="number"
              name="boreCount"
              id="boreCount"
              :class="{
                'is-invalid': vv.boreCount.$dirty && vv.boreCount.$invalid,
              }"
              class="form-control form-control-lg"
              v-model="vv.boreCount.$model"
            />
            <div class="invalid-feedback">
              {{ vv?.boreCount?.$errors[0]?.$message }}
            </div>
          </div>

          <div class="divider"></div>

          <div class="form-group">
            <label for="boreSizeTopId">Size of Bore (Top)</label>
            <select
              name="boreSizeTopId"
              :class="{
                'is-invalid':
                  vv.boreSizeTopId.$dirty && vv.boreSizeTopId.$invalid,
              }"
              id="boreSizeTopId"
              class="form-control form-control-lg"
              v-model="vv.boreSizeTopId.$model"
            >
              <option value="" disabled>------</option>
              <option
                v-for="option in data.boreSizeOptions"
                :key="option.boreSizeId"
                :value="option.boreSizeId"
              >
                {{ option.boreSizeDesc }}
              </option>
            </select>
            <div class="invalid-feedback">
              {{ vv?.boreSizeTopId?.$errors[0]?.$message }}
            </div>
            <small class="form-text ml-2"
              ><a href="#" @click="showHelpImg('Bore_Help.jpg')"
                >More Info</a
              ></small
            >
          </div>

          <div class="divider"></div>

          <div class="form-group">
            <label for="boreSizeBottomId">Size of Bore (Bottom)</label>
            <select
              name="boreSizeBottomId"
              :class="{
                'is-invalid':
                  vv.boreSizeBottomId.$dirty && vv.boreSizeBottomId.$invalid,
              }"
              id="boreSizeBottomId"
              class="form-control form-control-lg"
              v-model="vv.boreSizeBottomId.$model"
            >
              <option value="" disabled>------</option>
              <option
                v-for="option in data.boreSizeOptions"
                :key="option.boreSizeId"
                :value="option.boreSizeId"
              >
                {{ option.boreSizeDesc }}
              </option>
            </select>
            <div class="invalid-feedback">
              {{ vv?.boreSizeBottomId?.$errors[0]?.$message }}
            </div>
            <small class="form-text ml-2"
              ><a href="#" @click="showHelpImg('Bore_Help.jpg')"
                >More Info</a
              ></small
            >
          </div>

          <div class="divider"></div>

          <div class="form-group custom-control custom-switch text-center my-3">
            <input
              type="checkbox"
              name="additionalHolesAroundBore"
              id="additionalHolesAroundBore"
              :class="{
                'is-invalid':
                  vv.additionalHolesAroundBore.$dirty &&
                  vv.additionalHolesAroundBore.$invalid,
              }"
              class="custom-control-input"
              v-model="vv.additionalHolesAroundBore.$model"
            />
            <label class="custom-control-label" for="additionalHolesAroundBore"
              >Additional holes around bore?
            </label>
          </div>

          <div class="divider"></div>

          <div class="form-group">
            <label for="centerToCenterSpacingId"
              >Center to Center Spacing</label
            >
            <select
              name="centerToCenterSpacingId"
              :class="{
                'is-invalid':
                  vv.centerToCenterSpacingId.$dirty &&
                  vv.centerToCenterSpacingId.$invalid,
              }"
              id="centerToCenterSpacingId"
              class="form-control form-control-lg"
              v-model="vv.centerToCenterSpacingId.$model"
            >
              <option value="" disabled>------</option>
              <option
                v-for="option in data.cSpacingOptions"
                :key="option.centerToCenterId"
                :value="option.centerToCenterId"
              >
                {{ option.centerToCenterDesc }}
              </option>
            </select>
            <div class="invalid-feedback">
              {{ vv?.centerToCenterSpacingId?.$errors[0]?.$message }}
            </div>
            <small class="form-text ml-2"
              ><a href="#" @click="showHelpImg('Bore_Help.jpg')"
                >More Info</a
              ></small
            >
          </div>

          <div class="divider"></div>

          <div class="form-group">
            <label for="backsetId">Backset</label>
            <select
              name="backsetId"
              :class="{
                'is-invalid': vv.backsetId.$dirty && vv.backsetId.$invalid,
              }"
              id="backsetId"
              class="form-control form-control-lg"
              v-model="vv.backsetId.$model"
            >
              <option value="" disabled>------</option>
              <option
                v-for="option in data.backsetOptions"
                :key="option.backsetId"
                :value="option.backsetId"
              >
                {{ option.backsetDesc }}
              </option>
            </select>
            <div class="invalid-feedback">
              {{ vv?.backsetId?.$errors[0]?.$message }}
            </div>
            <small class="form-text ml-2"
              ><a href="#" @click="showHelpImg('Backset_Help.jpg')"
                >More Info</a
              ></small
            >
          </div>

          <div class="divider"></div>

          <div class="form-group">
            <label for="finishId">Lock Finish</label>
            <select
              name="finishId"
              :class="{
                'is-invalid': vv.finishId.$dirty && vv.finishId.$invalid,
              }"
              id="finishId"
              class="form-control form-control-lg"
              v-model="vv.finishId.$model"
            >
              <option value="" disabled>------</option>
              <option
                v-for="option in data.finishOptions"
                :key="option.finishId"
                :value="option.finishId"
              >
                {{ option.finishDesc }}
              </option>
            </select>
            <div class="invalid-feedback">
              {{ vv?.finishId?.$errors[0]?.$message }}
            </div>
            <small class="form-text ml-2"
              ><a href="#" @click="showHelpImg('Finish_Help.jpg')"
                >Finish Info</a
              ></small
            >
          </div>

          <div class="d-flex justify-content-center">
            <button
              class="btn control-btn px-5 my-3"
              id="continueBtn"
              @click="onSubmit"
              :disabled="vv.$invalid"
            >
              <div class="d-flex align-items-center justify-content-center">
                <div class="p-2">Continue</div>
                <BIconCaretRightFill />
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useVuelidate } from "@vuelidate/core";
import { required, requiredIf, minValue, integer } from "@vuelidate/validators";
import { reactive, toRef, inject } from "vue";
import axios from "axios";

export default {
  name: "FormDoorDetails",
  setup(props, { emit }) {
    const store = inject("store");

    const data = reactive({
      showImgModel: false,
      currentHelpImage: "",
      materialOptions: [
        {
          doorJambMaterialId: "0",
          doorJambMaterialDesc: "Loading...",
        },
      ],

      doorThicknessOptions: [
        {
          doorThicknessId: "0",
          doorThicknessDesc: "Loading...",
        },
      ],

      boreSizeOptions: [
        {
          boreSizeId: "0",
          boreSizeDesc: "Loading...",
        },
      ],

      cSpacingOptions: [
        {
          centerToCenterId: "0",
          centerToCenterDesc: "Loading...",
        },
      ],

      backsetOptions: [
        {
          backsetId: "0",
          backsetDesc: "Loading...",
        },
      ],

      finishOptions: [
        {
          finishId: "0",
          finishDesc: "Loading...",
        },
      ],
    });

    const getSelectOptions = () => {
      // get select options
      var baseURL;
      if (process.env.NODE_ENV === "production") {
        baseURL = "https://api.topnotchinc.com/v1/advant/";
      } else {
        baseURL = "/v1/Advant/";
      }
      
      axios.defaults.baseURL = baseURL;
      
      axios.defaults.headers = {
        "Content-type": "application/json",
        charset: "utf-8",
        Authorization: "Bearer " + process.env.VUE_APP_API_TOKEN,
      };

      const reqs = [
        axios.get("DoorJambMaterials"),
        axios.get("DoorThicknesses"),
        axios.get("BoreSizes"),
        axios.get("CenterToCenterSpacings"),
        axios.get("Backsets"),
        axios.get("Finishes"),
      ];

      store.setLoading(true);
      axios
        .all(reqs)
        .then(
          axios.spread((...responses) => {
            data.materialOptions = responses[0].data;
            data.doorThicknessOptions = responses[1].data;
            data.boreSizeOptions = responses[2].data;
            data.cSpacingOptions = responses[3].data;
            data.backsetOptions = responses[4].data;
            data.finishOptions = responses[5].data;
          })
        )
        .catch((errors) => {
          store.toast.error("Server error occurred.");
          console.log("Error", errors);
        })
        .then(function () {
          store.setLoading(false);
        });
    };

    const closeImgModal = () => {
      data.showImgModel = false;
    };

    const showHelpImg = (img) => {
      data.showImgModel = true;
      // data.currentHelpImage = `@/assets/img/${img}`;
      data.currentHelpImage = require(`../assets/img/${img}`);
    };

    getSelectOptions();

    // form section
    const fform = reactive({
      doorQuantity: 1,
      doorMaterialId: "",
      doorThicknessId: "",
      doorJambMaterialId: "",
      boreCount: 1,
      boreSizeTopId: "",
      boreSizeBottomId: "",
      additionalHolesAroundBore: false,
      centerToCenterSpacingId: "",
      backsetId: "",
      finishId: "",
    });

    const rules = {
      doorQuantity: { required, integer, minValue: minValue(1) },
      doorMaterialId: { required },
      doorThicknessId: { required },
      doorJambMaterialId: { required },
      boreCount: { required, integer, minValue: minValue(1) },
      boreSizeTopId: { required },
      boreSizeBottomId: {
        required: requiredIf(function () {
          return fform.boreCount > 1;
        }),
      },
      additionalHolesAroundBore: { required },
      centerToCenterSpacingId: { required },
      backsetId: { required },
      finishId: { required },
    };

    const vv = useVuelidate(rules, {
      doorQuantity: toRef(fform, "doorQuantity"),
      doorMaterialId: toRef(fform, "doorMaterialId"),
      doorThicknessId: toRef(fform, "doorThicknessId"),
      doorJambMaterialId: toRef(fform, "doorJambMaterialId"),
      boreCount: toRef(fform, "boreCount"),
      boreSizeTopId: toRef(fform, "boreSizeTopId"),
      boreSizeBottomId: toRef(fform, "boreSizeBottomId"),
      additionalHolesAroundBore: toRef(fform, "additionalHolesAroundBore"),
      centerToCenterSpacingId: toRef(fform, "centerToCenterSpacingId"),
      backsetId: toRef(fform, "backsetId"),
      finishId: toRef(fform, "finishId"),
    });

    const onSubmit = () => {
      vv.value.$touch();

      if (vv.value.$invalid) return;

      emit("detailsCompleted", fform);
    };

    return {
      vv,
      onSubmit,
      data,
      closeImgModal,
      showHelpImg,
    };
  },
};
</script>

<style scoped>
label {
  color: #191b1d;
}

#imgModal {
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 10000;
  border: none;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.img-full {
  position: fixed;
  width: auto;
  height: auto;
  max-height: 90vh;
  max-width: 80vw;
  z-index: 11000;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.close-button {
  position: absolute;
  top: 15px;
  right: 35px;
  color: #f1f1f1;
  font-size: 40px;
  font-weight: 700;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  z-index: 12000;
  cursor: pointer;
}
</style>