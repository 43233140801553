<template>
    <div class="d-flex align-items-end h-100">
      <div class="d-none">
        <label for="imgInput"> ></label>
        <input
          type="file"
          id="imgInput"
          capture="user"
          accept="image/*"
          @input="onInput"
          ref="imgInput"
        />
      </div>
      <button type="submit" class="btn control-btn px-5" @click="onBtnClick">
        <div class="d-flex align-items-center justify-content-center">
          <div class="p-2">Take Photo</div>
          <BIconCameraFill />
        </div>
      </button>
    </div>
</template>

<script>
export default {
  name: "FormDoorPhotoCapture",

  data() {
    return {
      file: null,
      hasImage: false,
    };
  },

  methods: {
    onBtnClick(event) {
      event.preventDefault();
      const input = this.$refs.imgInput;
      input.click();
    },

    onInput(event) {
      this.file = event.target.files[0];
      this.$emit("imageCapture", this.file);
    },

  },
};
</script>