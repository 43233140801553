<template>
  <form
    id="doorPhotoForm"
    @submit.prevent="onSubmit"
    novalidate
    class="h-100 w-100"
  >
    <div class="container-fluid h-100 py-3">
      <div
        class="d-flex flex-column h-100 justify-content-start align-items-center"
        v-if="currentImgType"
        :key="currentImgTypeIdx"
      >
        <div class="text-center door-photo-heading h-20">
          <h4 class="mb-0">{{ store.state.selectedDoor.doorTypeDesc }}</h4>
          <div class="heading-help-text" v-show="!doorDetailsActive">
            (Please position your device's camera so that the requested angle is
            captured. See below.)
          </div>
        </div>
        <div class="h-60 w-100">
          <div class="h-100 d-flex flex-column justify-content-center">
            <div
              v-show="!previewing && !doorDetailsActive"
              class="p-2 door-photo-helper text-center w-100 img-container"
            >
              <img
                :src="helperImgSrc"
                alt="Door Photo Helper Image"
                class="door-photo-helper-img"
              />
            </div>
            <div
              v-show="previewing"
              class="p-2 door-photo-preview text-center w-100 img-container h-100"
            >
              <img alt="" ref="previewImg" class="preview-img" />
            </div>
            <div
              class="door-photo-helper-label text-center"
              v-show="!doorDetailsActive"
            >
              <h5>
                {{ currentImgType.imageTypeDesc }}
              </h5>
            </div>
          </div>
        </div>

        <div
          class="mt-auto h-20"
          :key="currentImgTypeIdx"
          v-show="!previewing && !doorDetailsActive"
        >
          <form-door-photo-capture @image-capture="onImageCapture" />
        </div>

        <div
          id="doorDetails"
          v-if="doorDetailsActive"
          :key="store.state.selectedDoor.doorTypeDesc"
        >
          <form-door-details @details-completed="onCompleteDetails" />
        </div>

        <div
          v-if="previewing && !onLastImageType && !doorDetailsActive"
          :key="currentImgTypeIdx"
          class="d-flex align-items-end justify-content-center w-100 flex-wrap mt-auto h-20"
        >
          <div class="w-100 text-center mb-1">Advance to next image?</div>
          <button
            class="btn btn-danger btn-lg font-weight-bold px-4 mr-1"
            @click="onRetakeImage"
          >
            <div class="d-flex align-items-center justify-content-center">
              <div class="mr-1">Re-take</div>
              <BIconArrowRepeat class="mr-n1" />
            </div>
          </button>
          <button
            class="btn btn-success btn-lg font-weight-bold px-4 ml-1"
            @click="onAcceptImage"
          >
            <div class="d-flex align-items-center justify-content-center">
              <div class="">Continue</div>
              <BIconCaretRightFill class="mr-n1" />
            </div>
          </button>
        </div>

        <div
          v-if="previewing && onLastImageType && !doorDetailsActive"
          :key="currentImgTypeIdx"
          class="d-flex align-items-end justify-content-center w-100 flex-wrap mt-auto h-20"
        >
          <div class="w-100 text-center mb-1">
            Done adding photos for
            <span class="font-italic">{{
              store.state.selectedDoor.doorTypeDesc
            }}</span
            >?
          </div>
          <button
            class="btn btn-danger btn-lg font-weight-bold px-4 mr-1"
            @click="onCancelDoorType"
          >
            <div class="d-flex align-items-center justify-content-center">
              <div class="mr-1">Cancel</div>
              <BIconXCircle class="mr-n1" />
            </div>
          </button>
          <button
            class="btn btn-success btn-lg font-weight-bold px-4 ml-1"
            @click="onCompleteDoorPhotos"
          >
            <div class="d-flex align-items-center justify-content-center">
              <div class="mr-1">Submit</div>
              <BIconCaretRightFill class="mr-n1" />
            </div>
          </button>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import api from "@/resources/axios";
import FormDoorPhotoCapture from "@/components/FormDoorPhotoCapture.vue";
import FormDoorDetails from "@/components/FormDoorDetails.vue";

const resizeImage = ({ file, maxSize }) => {
  const reader = new FileReader();
  const image = new Image();
  const canvas = document.createElement("canvas");

  const resize = () => {
    let { width, height } = image;

    if (width > height) {
      if (width > maxSize) {
        height *= maxSize / width;
        width = maxSize;
      }
    } else if (height > maxSize) {
      width *= maxSize / height;
      height = maxSize;
    }

    canvas.width = width;
    canvas.height = height;
    canvas.getContext("2d").drawImage(image, 0, 0, width, height);

    const dataUrl = canvas.toDataURL("image/jpeg");
    return dataUrl;
  };

  return new Promise((ok, no) => {
    if (!file.type.match(/image.*/)) {
      no(new Error("Not an image"));
      return;
    }

    reader.onload = (readerEvent) => {
      image.onload = () => ok(resize());
      image.src = readerEvent.target.result;
    };

    reader.readAsDataURL(file);
  });
};

export default {
  name: "FormDoorPhoto",
  components: { FormDoorPhotoCapture, FormDoorDetails },
  inject: ["store"],

  data() {
    return {
      previewing: false,
      doorDetailsActive: true,
      images: {},
      imageTypes: [],
      currentImgTypeIdx: -1,
      door: {},
    };
  },

  mounted() {
    this.getImageTypes();
  },

  computed: {
    currentImgType() {
      if (this.imageTypes.length > 0) {
        return this.imageTypes[this.currentImgTypeIdx];
      } else {
        return {
          imageTypeId: -1,
          imageTypeDesc: "",
        };
      }
    },

    helperImgSrc() {
      if (this.currentImgType) {
        if (this.currentImgType.imageTypeDesc) {
          var src = require("../assets/img/" +
            this.currentImgType.imageTypeDesc.replace(" ", "_") +
            ".png");
          return src;
        }
      }
      return "";
    },

    onLastImageType() {
      return !(this.currentImgTypeIdx < this.imageTypes.length - 1);
    },
  },

  methods: {
    getImageTypes() {
      var vm = this;

      vm.store.setLoading(true);
      api
        .get("ImageTypes")
        .then((response) => {
          vm.imageTypes = response.data;
        })
        .catch((error) => {
          vm.store.toast.error("Server error occurred.");
          console.log("Error", error);
        })
        .then(function () {
          vm.store.setLoading(false);
          vm.currentImgTypeIdx = 0;
        });
    },

    onImageCapture(image) {
      this.readURL(image);
    },

    readURL(file) {
      var vm = this;
      // START: preview original
      if (!file.type.match(/image.*/)) {
        console.log("Error, not a valid image.");
        return;
      }

      const reader = new FileReader();
      // reader.onload = (e) => (this.$refs.previewImg.src = e.target.result);
      reader.readAsDataURL(file); // convert to base64 string
      // END: preview original

      resizeImage({ file: file, maxSize: 1000 })
        .then((resizedImage) => {
          // resizedImg = URL.createObjectURL(resizedImage);
          this.previewing = true;
          this.$refs.previewImg.src = resizedImage;

          var base64Img = resizedImage.split(",");

          this.images[this.currentImgType.imageTypeId] = {
            doorId: this.door.doorId,
            imageTypeId: this.currentImgType.imageTypeId,
            imageData: base64Img[1],
            mimeData: base64Img[0],
          };
        })
        .catch((err) => {
          vm.store.toast.error("Error Processing Captured Image.");
          console.error(err);
        });
    },

    onRetakeImage() {
      delete this.images[this.currentImgType.imageTypeId];
      this.previewing = false;
    },

    onAcceptImage() {
      this.previewing = false;
      this.currentImgTypeIdx += 1;
    },

    onCancelDoorType() {
      if (
        confirm(
          "Are you sure you want to start over taking photos for " +
            this.store.state.selectedDoor.doorTypeDesc + "?"
        )
      ) {
        // delete images, start photos over
        this.images = {};
        this.currentImgTypeIdx = 0;
        this.previewing = false;
      }
    },

    onCompleteDoorPhotos() {
      // upload images

      var store = this.store;
      store.setLoading(true);
      var imagesArr = Object.values(this.images);

      var errorToastId = 0;

      // send a post request for each image
      imagesArr.forEach((img, idx) => {
        api
          .post("DoorImage", img)
          .then(() => {
            if (idx == imagesArr.length - 1) {
              store.setLoading(false);
              store.markDoorTypeComplete();
              this.$router.push({ path: "door_select" });

              store.toast.success(
                "All photos for " +
                  store.state.selectedDoor.doorTypeDesc +
                  " uploaded successfully.",
                {
                  position: "top-right",
                  timeout: 4000,
                }
              );
            }
          })
          .catch((error) => {
            if (errorToastId == 0) {
              errorToastId = store.toast.error("Error uploading images.");
            }
            console.log("Error", error);
            store.setLoading(false);
          });
      });
    },

    onCompleteDetails(detailForm) {
      // upload details to api
      var store = this.store;

      var rfqId = this.store.getRfqObj().rfqId;
      detailForm.rfqId = rfqId;
      detailForm.doorTypeId = this.store.state.selectedDoor.doorTypeId;

      store.setLoading(true);
      api
        .post("Door", detailForm)
        .then((response) => {
          this.door = response.data;
          this.previewing = false;
          this.doorDetailsActive = false;
          store.toast.success(
            store.state.selectedDoor.doorTypeDesc +
              " Details Added Successfully.",
            {
              position: "top-right",
              timeout: 4000,
            }
          );
        })
        .catch((error) => {
          store.toast.error("Error uploading door details.");
          console.log("Error", error);
        })
        .then(function () {
          store.setLoading(false);
        });
    },
  },
};
</script>

<style scoped>
.door-photo-helper-img,
.preview-img {
  max-width: 100%;
  max-height: 100%;
}

.img-container {
  height: calc(90% - 0.5rem);
}

.heading-help-text {
  font-size: 0.8rem;
}

#doorDetails {
  height: calc(100% - 3rem);
  min-width: 70%;
}
</style>